
const config = {
    gatewayUrl: 'https://dev.api.safepag.com/gateway'
};

if (window.location.host.indexOf('dev.checkout') === 0) {
    config.gatewayUrl = 'https://dev.api.safepag.com/gateway';
}

if (window.location.host.indexOf('stg.checkout') === 0) {
    config.gatewayUrl = 'https://staging.api.safepag.com/gateway';
}

if (window.location.host.indexOf('staging.checkout') === 0) {
    config.gatewayUrl = 'https://staging.api.safepag.com/gateway';
}

if (window.location.host.indexOf('checkout') === 0) {
    config.gatewayUrl = 'https://api.safepag.com/gateway';
}

if (window.location.host.indexOf('tst.checkout') === 0) {
    config.gatewayUrl = 'https://tst.api.safepag.com/gateway';
}

export default config;