import React from 'react';
import { Box, Flex, SimpleGrid, Image, Text } from '@chakra-ui/react';
import { PRODUCTS } from '../../constants/products';
import { FaCheck } from 'react-icons/fa';

interface CarouselProps {
  onAdd: (id: number) => void,
  chosenList: number[],
}

const Carousel: React.FC<CarouselProps> = ({ chosenList, onAdd }) => {
  return (
    <Box maxW={['320px', '480px', '820px', '5xl', '6xl', 'full']} pt={8} pb={8} justifyContent={'center'}>
      <SimpleGrid columns={{ 
        base: 2,
        md: 3
       }} spacing={10}>
      {PRODUCTS.map(({ id, image, name, price }) => (
          <Box position='relative' boxShadow='lg' cursor='pointer' borderRadius={10} bgColor={'white'} w={'full'} p={8} onClick={() => {
            onAdd(id);
          }}>
            {chosenList.indexOf(id) >= 0 && (
              <Box bgColor={'#85D7B8'} rounded={'full'} p={3} pos={'absolute'} top={6} bottom={'auto'} right={6}>
                <FaCheck color={'white'} width="60px" />
              </Box>
            )}
            <Image src={image} w={'full'} />
            <Flex justifyContent={'space-between'}>
              <Box pt={4}>
                <Text color={'gray.800'} lineHeight={'16,41px'}>
                  {name}
                </Text>
              </Box>
              <Box pt={4} fontWeight={800} color={'#4388D8'}>
                <Text fontSize={'20px'} lineHeight={'21,09px'}>
                  {price}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Carousel;
