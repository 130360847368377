import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { LangProvider } from './contexts/LangContext';
import Ecommerce from './pages/Ecommerce';
import { CheckoutProvider } from './contexts/CheckoutContext';
import ExternalRoute from './routes/ExternalRoute';
import Payout from './pages/Payout';

export const App = () => (
  <ChakraProvider theme={theme}>
    <LangProvider>
      <CheckoutProvider>
        <Routes>
          <Route path="*" element={<ExternalRoute />} />
          <Route path="/" element={<Ecommerce />} />
          <Route path="/payout" element={<Payout />} />
          <Route path="/v1/payout/capture" element={<Payout />} /> 
        </Routes>
      </CheckoutProvider>
    </LangProvider>
  </ChakraProvider>
);
