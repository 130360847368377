import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { FiAlertTriangle } from 'react-icons/fi';

const Alert: React.FC = () => {
  const bg = useColorModeValue('#FAFAFA', 'gray.800');
  const alert = useColorModeValue('black', '#FF6363');

  return (
    <Box>
      <Box
        bgColor={bg}
        w={{ base: 'full', md: '95%' }}
        ml={{ base: 0, sm: 0, md: 10 }}
        mr={{ base: 0, sm: 0, md: 10 }}
        mt={{ base: 0, sm: 10, md: 0 }}
        rounded={8}
        justifyContent={'center'}
        border={'1px solid #74769B'}
      >
        <Flex p={10} alignItems={'center'} flexDir={{ base: 'column', sm: 'column', md: 'row' }}>
          <Box pr={{ base: 0, sm: 0, md: 8 }}>
            <FiAlertTriangle size={'50px'} color={alert} />
          </Box>
          <Text textAlign={{ base: 'center', sm: 'center', md: 'left' }} color={alert}>
            Realize o pagamento utilizando uma conta bancária com CPF de mesma titularidade
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default Alert;
