import { PayInRequiredFieldsForm, PayInResultData } from "../types/payin";
import api from "./api";

interface RegisterPayInParams {
    jwtToken: string;
    apiKey: string;
    formParams?: PayInRequiredFieldsForm
}
export const registerPayIn = ({ jwtToken, apiKey, formParams }: RegisterPayInParams): Promise<PayInResultData> => {
    
    return api.post<PayInResultData>('/v1/payin', {
        jwt: jwtToken,
        accountApiKey: apiKey,
        ...(formParams || {}),
    }, {
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey || ''
        }
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
    
}