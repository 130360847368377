import api from './api';

interface Merchant {
  apiKey: string;
  secret: string;
  code: string;
}
type MerchantResponse = Merchant[];

const UNSAFE_getMerchants = () => {
  return api
    .get<MerchantResponse>('/UNSAFE_merchants')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export default UNSAFE_getMerchants;
