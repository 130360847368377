import React, { useEffect } from 'react';
import { Box, Center, Container, Grid, Spinner, Text } from '@chakra-ui/react';
import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';
import Checkout from '../components/checkout/Checkout';
import { useCheckout } from '../contexts/CheckoutContext';
import { registerPayIn } from '../services/registerPayIn';
import Error from './Error';

const ExternalCheckout: React.FC = () => {
  const {
      setPayInResult,
      setLoading,
      error,
      loading,
      getRawJwt,
      getApiKey,
      getDecodedParams,
      setSubmitted,
      setError,
  } = useCheckout();

  const decodedParams = getDecodedParams();

  
const [ apiKey, rawJwt ] = [ getApiKey(), getRawJwt() ];
  useEffect(() => {
    setLoading(true);

    if (decodedParams.isValid && decodedParams.missingParams.length > 0) {
      console.info('missing params', decodedParams.missingParams);
      setSubmitted(false);
      setLoading(false);
      return;
    } 
    
    registerPayIn({ 
      jwtToken: rawJwt || '',
      apiKey: apiKey || '',
    })
      .then((data) => {
        setPayInResult(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        if (err.response?.data?.code) {
          setError({ code: err.response?.data?.code, error: err.response?.data?.error });  
        } else {
          setError({ code: '1007', error: 'Internal error' });  
        }        
      });
  }, []);

  const contentBlock = (
    <Grid minH="100vh">
      <NavBar />
      <Container maxW="8xl">
        <Checkout title="Checkout Seguro" />
        <Box pt={{ base: 8, sm: 8, md: 32 }} w={{ base: 'full', sm: 'full', md: '70%' }}>
          <Text color={'#A1A1A1'} textAlign={{ base: 'justify', sm: 'justify', md: 'left' }}>
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical
            Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at
            Hampden-Sydney College in Virginia
          </Text>
        </Box>
        <Footer />
      </Container>
    </Grid>
  )

  return (
    <Box fontSize="xl">

      {error ? <Error /> : null}
    
      {loading ? (
        <Center minH="100vh">
          <Spinner />
        </Center>
      ) : null}

      {Boolean(!loading && !error) && (contentBlock)}

    </Box>
  );
};

export default ExternalCheckout;
