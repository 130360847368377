import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error from '../pages/Error';
import ExternalCheckout from '../pages/ExternalCheckout';

const ExternalRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/direct/deposit/:apiKey/:jwtToken">
        <Route index element={<ExternalCheckout />} />
        <Route path="/direct/deposit/:apiKey/:jwtToken/error" element={<Error />} />
      </Route>
    </Routes>
  );
};

export default ExternalRoute;
