import React, { Dispatch, PropsWithChildren } from 'react';
import { Button, useColorModeValue } from '@chakra-ui/react';
import { CheckoutPaymentType } from '../../contexts/CheckoutContext';

interface TogglerBoxProps {
  children: React.ReactNode;
  setIsSelected: Dispatch<React.SetStateAction<CheckoutPaymentType>>;
  id: CheckoutPaymentType;
  focus: string;
}

const TogglerBox: React.FC<PropsWithChildren<TogglerBoxProps>> = ({ children, setIsSelected, id, focus }) => {
  const color = useColorModeValue('#F8F8F8', 'gray.800');

  return (
    <Button
      boxShadow={'lg'}
      bgColor={color}
      h={32}
      rounded={'lg'}
      border={`2px solid ${focus}`}
      onClick={() => setIsSelected(id)}
      _hover={{ transform: 'scale(1.05)', transition: 'all .4s ease' }}
      _focus={{ outline: 'none' }}
    >
      {children}
    </Button>
  );
};

export default TogglerBox;
