import React from 'react';
import { Box, Container, Flex, HStack, Image, Text } from '@chakra-ui/react';
import logo from '../../assets/images/logo.svg';
import { BsShieldCheck } from 'react-icons/bs';

const NavBar: React.FC = () => {
  return (
    <Box bgColor={'#13263C'} px={{ base: 8, sm: 8, md: 4 }} h={32}>
      <Container maxW={'8xl'} h={'full'}>
        <Flex p={5} justifyContent={'space-between'} h={'full'} alignItems="center">
          <Box>
            <Image src={logo} />
          </Box>
          <HStack color={'white'} flexDir={{ base: 'row-reverse', sm: 'row-reverse', md: 'row' }}>
            <BsShieldCheck size="35px" />
            <Box fontSize={{ base: '12px', sm: '12px', md: '14px' }} lineHeight={'16px'} textAlign={'left'}>
              <Text color={'#A2BFC1'}>Você está em uma </Text>
              <Text>página segura</Text>
            </Box>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default NavBar;
