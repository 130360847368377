import React, { useState } from 'react';
import { Box, Button, Container, Flex, Grid, Image, Text, useToast } from '@chakra-ui/react';
import Footer from '../components/footer/Footer';
import logo from '../assets/images/logo.svg';
import safe from '../assets/images/safe.svg';
import Carousel from '../components/carousel/Carousel';
import { FaShoppingBag } from 'react-icons/fa';
import { PRODUCTS } from '../constants/products';
import { encodeJwt } from '../utils/jwt';
import UNSAFE_getMerchants from '../services/UNSAFE_getMerchants';
import { useNavigate } from 'react-router-dom';

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const Ecommerce: React.FC = () => {

  const [ selectedProducts, setSelecetedProducts ] = useState<number[]>([]);
  const toast = useToast();
  const navigate = useNavigate();

  function goToCheckout() {
    let amount = 0;
    
    selectedProducts.map(id => PRODUCTS.find(d => d.id == id)).filter(t => t)
      .map(row => {
        amount = amount + (row?.price || 0);
        return row;
      });

    UNSAFE_getMerchants().then((res) => {

      const merchant = res.data.find((m) => m.code == 'SAFETSHIRT');

      if (!merchant) {
        throw new Error("merchant SAFETSHIRT não encontrado");
      }

      const jwt = encodeJwt({
        amount,
        paymentMethod: 'PIX',
        externalReference: `ELR+${getRandomArbitrary(1000, 99999)}+${getRandomArbitrary(0, 10)}`,
        merchantCustomerCode: `DCK+${getRandomArbitrary(1000, 99999)}+${getRandomArbitrary(0, 10)}`,
        customerProvidedName: 'João Coelho', // TODO remove
        customerEmail: 'joaoteste@gmail.com', // TODO remove
        customerDocument: '03006052005' // TODO remove
      }, merchant.secret);

      navigate(`/direct/deposit/${merchant.apiKey}/${jwt}`);
      toast({
        position: 'top-right',
        description: 'Redirecting to CHECKOUT SAFEPAG...',
        duration: 9090
      });
    }).catch((err) => {
      toast({
        position: 'top-right',
        description: err.message,
      })
    });

  }

  return (
    <Box fontSize="xl">
      <Grid minH="100vh">
        <Box bgColor={'#13263C'} px={{ base: 8, sm: 8, md: 4 }}>
          <Container maxW={'8xl'}>
            <Flex pt={20} pb={20} justifyContent={'space-between'} h={32} alignItems="top">
              <Box>
                <Image src={safe} width={{ base: '150px', sm: '150px', md: '200px' }} />
              </Box>
              <Box ml='auto'>
                <Image src={logo} />
              </Box>

              <Button ml={5} variant='solid' bg={'white'} p={4} onClick={() => {
                if (selectedProducts.length == 0) {
                  toast({
                    position: 'top-right',
                    description: 'Selecione um ou mais produtos',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                  });
                } else {
                  goToCheckout();
                }
                
              }}>
                 <Text mr={3} color=''>Go to Checkout</Text>
                  <Box position='absolute' top='0' right='0' background='red'
                    w={5}
                    h={5}
                    rounded='full'
                    textAlign={'center'}
                  >
                    <Text fontSize='sm' color='white'>{selectedProducts.length}</Text>
                  </Box>
                  <FaShoppingBag size={30} color='' />
                  
              </Button>
            </Flex>
          </Container>
        </Box>

        <Container maxW={'8xl'}>
          <Carousel
            chosenList={selectedProducts}
            onAdd={(id) => {
              if (selectedProducts.indexOf(id) >= 0) {
                setSelecetedProducts(selectedProducts.filter(r => r !== id))
              } else {
                setSelecetedProducts([ ...selectedProducts, id ])
              }
            }}/>
          </Container>
        <Container maxW="8xl">
          <Footer />
        </Container>
      </Grid>
    </Box>
  );
};

export default Ecommerce;
