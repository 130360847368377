import React from 'react';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../color-mode-switcher/ColorModeSwitcher';

const Footer: React.FC = () => {
  return (
    <Box textAlign={'center'} pt={10} h={32}>
      <Divider color={'#898989'} />
      <Flex justifyContent={'center'} alignItems={'center'} pt={8}>
        <Text>Developed and Maintained by Safepag</Text>
        <ColorModeSwitcher />
      </Flex>
    </Box>
  );
};

export default Footer;
