import React from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import Form from '../form/Form';
import PaymentToggler from '../payment-toggler/PaymentToggler';
import Alert from '../alert/Alert';
import Qrcode from '../qr-code/Qrcode';
import { useCheckout } from '../../contexts/CheckoutContext';

interface CheckoutProps {
  title: string;
}

const Checkout: React.FC<CheckoutProps> = ({ title }) => {
  const { submitted, payInResult } = useCheckout();

  return (
    <>
      <Heading pb={10} pt={10} px={4}>
        {submitted ? 'Concluir pagamento' : title}
      </Heading>

      {payInResult ? (
        <Qrcode />
      ) : (
        <SimpleGrid columns={{ base: 1, sm: 1, md: 2 }} textAlign={'left'} px={4}>
          <Box>
            <PaymentToggler />
            <Form />
          </Box>

          <Box>
            <Alert />
          </Box>
        </SimpleGrid>
      )}
    </>
  );
};

export default Checkout;
