import React from 'react';
import { Box, Container, Grid, useToast } from '@chakra-ui/react';
import Footer from '../components/footer/Footer';
import PayoutForm from '../components/form/PayoutForm';
import { PayOutObject } from '../types/payout';
import UNSAFE_getMerchants from '../services/UNSAFE_getMerchants';
import api from '../services/api';
import { encodeJwt } from '../utils/jwt';
import NavBar from '../components/navbar/NavBar';

const Payout: React.FC = () => {
  const toast = useToast();
  const handleSubmitForm = (data: PayOutObject): void => {
    UNSAFE_getMerchants()
      .then((merchants) => {
        const merchant = merchants.data.find((m) => m.code == 'SAFETSHIRT');
        return merchant;
      })
      .then((merchant) => {
        return api.post(
          '/v1/payout/capture',
          {
            jwt: encodeJwt(
              {
                paymentMethod: 'PIX',
                externalReference: Math.floor(Math.random() * 9999),
                customerProvidedName: data.name,
                customerEmail: data.email,
                customerDocument: data.cpf.replace(/[^0-9]/g, ''),
                amount: data.amount,
                merchantCustomerCode: Math.floor(Math.random() * 9999),
                pixInfo:
                  data.pixType == 'pix'
                    ? {
                        pixKey: data.pixKey.replace(/[^0-9]/g, '')
                      }
                    : {
                        bankCode: data.bankCode,
                        accountNumber: data.accountNumber,
                        branchCode: data.branchCode,
                        accountType: data.accountType
                      },
                entityName: data.entityName,
                entityCountry: data.entityCountry
              },
              merchant?.secret || ''
            )
          },
          {
            headers: {
              'x-api-key': `${merchant?.apiKey}`
            }
          }
        );
      })
      .then(() => {
        toast({
          title: 'Success!',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
      })
      .catch((err) => {
        console.error(err);

        let message = err.message;
        if (err.response?.data) {
          message = `${err.response.data.code}: ${err.response.data.error}`
        }
        
        toast({
          title: 'Error!',
          description: `${message}`,
          duration: 9000,
          isClosable: true,
          status: 'error'
        });
      });
  };

  return (
    <Box fontSize="xl">
      <Grid minH="100vh">
        <NavBar />

        <Container maxW="8xl">
          <PayoutForm onSubmit={handleSubmitForm} />
          <Footer />
        </Container>
      </Grid>
    </Box>
  );
};

export default Payout;
