import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../utils/yupExtended';
import InputMask from 'react-input-mask';
import { FormInterface, useCheckout } from '../../contexts/CheckoutContext';
import { registerPayIn } from '../../services/registerPayIn';

const schema = yup.object().shape({
  name: yup.string().min(3, 'Nome deve ter pelo menos 3 caracteres').required('Nome é obrigatório'),
  email: yup.string().email('Email inválido , favor inserir email válido').min(8, 'Email é obrigatório').required('Digite um e-mail válido'),
  cpf: yup.string().isValidCpf('CPF inválido, favor inserir documento válido').required()
});

const Form: React.FC = () => {
  const {
    setSubmitted,
    setError,
    setLoading,
    getApiKey,
    getRawJwt,
    getDecodedParams
  } = useCheckout();

  const { data: jwtData } = getDecodedParams();
  const [ apiKey, rawJwt ] = [ getApiKey(), getRawJwt() ];

  const {
    register,
    handleSubmit,
    formState: { errors },  
    formState,
    getValues,
    trigger
  } = useForm<FormInterface>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: (
      jwtData ? {
        name: jwtData.customerProvidedName,
        cpf: jwtData.customerDocument,
        email: jwtData.customerEmail
      } : {

      }
    )
  }); 

  useEffect(() => {
    trigger();
  }, []);

  const onSubmit = (data: FormInterface) => {
    setLoading(true);
    registerPayIn({ 
      jwtToken: rawJwt || '',
      apiKey: apiKey || '',
      formParams: {
        customerProvidedName: data.name || '',
        customerDocument: data.cpf || '',
        customerEmail: data.email || ''
      }
    })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch((err) => {
        setLoading(false);

        if (err.response?.data?.code) {
          setError({ code: err.response?.data?.code, error: err.response?.data?.error });  
        } else {
          setError({ code: '1007', error: 'Internal error' });  
        }        
      });
  };

  const formValues = getValues();

  console.info(formValues);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box rounded={'lg'} pt={5}>
          <Stack spacing={4}>
            <HStack>
              <FormControl id="name" isRequired isInvalid={!!errors?.name?.message}>
                <FormLabel>Nome</FormLabel>
                <Input type="text" {...register('name')} border={'2px solid'}
                  disabled={Boolean(jwtData?.customerProvidedName == formValues.name) && !errors?.name?.message}
                />
                <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
              </FormControl>
            </HStack>
            <FormControl id="email" isRequired isInvalid={!!errors?.email?.message}>
              <FormLabel>E-mail</FormLabel>
              <Input type="email" {...register('email')}
                disabled={Boolean(jwtData?.customerEmail == formValues.email) && !errors?.email?.message}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl id="cpf" isRequired isInvalid={!!errors?.cpf?.message}>
              <FormLabel>CPF</FormLabel>
              <Input type="text" {...register('cpf')} as={InputMask} mask="999.999.999-99"
                disabled={Boolean(jwtData?.customerDocument == formValues.cpf) && !errors?.cpf?.message}
                />
              <FormErrorMessage>{errors?.cpf?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </Box>

        <Box pt={10}>
          <Text>Valor</Text>
          <Flex
            flexDir={{ base: 'column', sm: 'column', md: 'row' }}
            justifyContent={{ base: 'center', sm: 'center', md: 'space-between' }}
          >
            <Heading w="full" pt={2} pb={2} color={'#85D7B8'} fontSize={{ base: 'xl', sm: 'xl', md: '3xl' }}>
              {jwtData?.amount}
            </Heading>
            <Button
              mb={{ base: 5, sm: 5, md: 0 }}
              w={{ base: 'full', sm: 'full', md: 60 }}
              h={16}
              color={'white'}
              bgGradient={'linear-gradient(92.65deg, #64D761 -19.38%, #9CD7F8 115.55%)'}
              _hover={{
                bg: 'linear-gradient(92.65deg, #64D761 -19.38%, #9CD7F8 115.55%)',
                transform: 'scale(1.05)'
              }}
              onClick={() => setSubmitted(true)}
              type="submit"
              disabled={!formState.isValid}
            >
              Pagar com Pix
            </Button>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
