import React from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Stack
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../utils/yupExtended';
import InputMask from 'react-input-mask';
import { useCheckout } from '../../contexts/CheckoutContext';
import { BANK_LIST } from '../../constants/bankList';
import { ACCOUNT_TYPE } from '../../constants/accountType';
import { PayOutObject } from '../../types/payout';

const schema = yup.object().shape({
  name: yup.string().min(3).required('Nome é obrigatório'),
  email: yup.string().email('Email inválido , favor inserir email válido').min(8, 'Email é obrigatório').required(),
  cpf: yup.string().isValidCpf('Cpf inválido, favor inserir documento válido').required(),
  amount: yup.number().required(),
  pixType: yup.string().required(),
  pixKey: yup.string(),
  bankCode: yup.string(),
  branchCode: yup.string(),
  accountNumber: yup.string(),
  accountType: yup.string(),
  entityName: yup.string(),
  entityCountry: yup.string()
});

interface PayoutFormProps {
  onSubmit: (data: PayOutObject) => void;
}

const PayoutForm: React.FC<PayoutFormProps> = ({ onSubmit }) => {
  const {
    setSubmitted
  } = useCheckout();

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    getValues,
    watch
  } = useForm<PayOutObject>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {}
  });

  const method = watch('pixType');

  return (
    <Container>
      <Heading pb={5} pt={10}>
        Payout
      </Heading>
      <form
        onSubmit={handleSubmit((data: PayOutObject) => {
          onSubmit(data);
        })}
      >
        <Flex align="center" justifyContent="center">
          <Box rounded={'lg'} pt={5} w={'full'}>
            <Stack spacing={4}>
              <HStack>
                <FormControl id="name" isRequired isInvalid={!!errors?.name?.message}>
                  <FormLabel>Nome</FormLabel>
                  <Input type="text" {...register('name')}></Input>
                  <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
                </FormControl>
              </HStack>
              <FormControl id="email" isRequired isInvalid={!!errors?.email?.message}>
                <FormLabel>E-mail</FormLabel>
                <Input type="email" {...register('email')} />
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="cpf" isRequired isInvalid={!!errors?.cpf?.message}>
                <FormLabel>CPF</FormLabel>
                <Input type="text" as={InputMask} mask="999.999.999-99" {...register('cpf')} />
                <FormErrorMessage>{errors?.cpf?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="amount" isRequired>
                <FormLabel>Amount</FormLabel>
                <Input type="number" {...register('amount')} />
              </FormControl>
              <FormControl id="pixType" isRequired>
                <FormLabel>Tipo</FormLabel>
                <Select {...register('pixType')} placeholder="Selecionar">
                  <option value="pix">Chave Pix</option>
                  <option value="bank-account">Dados Bancários</option>
                </Select>
              </FormControl>
              {method == 'pix' && (
                <FormControl id="pixKey" isRequired>
                  <FormLabel>Chave Pix</FormLabel>
                  <Input type="text" {...register('pixKey')} />
                </FormControl>
              )}
              {method == 'bank-account' && (
                <>
                  <FormControl id="bankCode" isRequired>
                    <FormLabel>Banco</FormLabel>
                    <Select {...register('bankCode')} placeholder="Selecionar">
                      {BANK_LIST.map(({ id, name }) => (
                        <option value={id}>{`${id} - ${name}`}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl id="branchCode" isRequired>
                    <FormLabel>Agência</FormLabel>
                    <Input type="text" {...register('branchCode')} />
                  </FormControl>
                  <FormControl id="accountNumber" isRequired>
                    <FormLabel>Número da Conta</FormLabel>
                    <Input type="text" {...register('accountNumber')} />
                  </FormControl>
                  <FormControl id="accountType" isRequired>
                    <FormLabel>Tipo de Conta</FormLabel>
                    <Select {...register('accountType')} placeholder="Selecionar">
                      {ACCOUNT_TYPE.map(({ id, name }) => (
                        <option value={id}>{name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              <FormControl id="entityName">
                <FormLabel>Nome da Entidade</FormLabel>
                <Input type="text" {...register('entityName')} />
              </FormControl>
              <FormControl id="entityCountry">
                <FormLabel>País da Entidade</FormLabel>
                <Input type="text" {...register('entityCountry')} />
              </FormControl>
            </Stack>
          </Box>
        </Flex>

        <Box pt={10}>
          <Flex
            flexDir={{ base: 'column', sm: 'column', md: 'row' }}
            justifyContent={{ base: 'center', sm: 'center', md: 'space-between' }}
          >
            <Heading w="full" pt={2} pb={2} color={'#85D7B8'} fontSize={{ base: 'xl', sm: 'xl', md: '3xl' }}>
              {getValues().amount}
            </Heading>
            <Button
              mb={{ base: 5, sm: 5, md: 0 }}
              w={{ base: 'full', sm: 'full', md: 60 }}
              h={16}
              color={'white'}
              bgGradient={'linear-gradient(92.65deg, #64D761 -19.38%, #9CD7F8 115.55%)'}
              _hover={{
                bg: 'linear-gradient(92.65deg, #64D761 -19.38%, #9CD7F8 115.55%)',
                transform: 'scale(1.05)'
              }}
              onClick={() => setSubmitted(true)}
              type="submit"
              disabled={!formState.isValid}
            >
              Pagar
            </Button>
          </Flex>
        </Box>
      </form>
    </Container>
  );
};

export default PayoutForm;
