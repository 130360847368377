import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack
} from '@chakra-ui/react';
import Alert from '../alert/Alert';
import { useCheckout } from '../../contexts/CheckoutContext';
import QRCode from 'qrcode.react';

const Qrcode: React.FC = () => {
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
  const [copiedText, setCopiedText] = useState<string>('COPIAR');

  const {
    payInResult
  } = useCheckout();



  const color = useColorModeValue('#FAFAFA', 'gray.800');
  
  if (!payInResult) {
    return <>No data</>;
  }

  const { amount, pixCopiaECola, qrCode, externalReference, pspReceiver } = payInResult || {};

  return (
    <Container maxW="8xl">
      <Box
        maxW={'full'}
        p={{ base: 6, md: 12 }}
        bgColor={color}
        rounded={8}
        justifyContent={'center'}
        border={'1px solid #F9F9F9'}
      >
        <Flex flexDir={{ base: 'column', sm: 'column', md: 'row' }}>
          <Stack>
            <Text color={'#A1A1A1'}>O QR Code Pix está pronto!</Text>
            {qrCode.includes('data:') ? (
              <Image id={'qrcode'} src={qrCode} pt={4} />
            ) : (
              <QRCode value={qrCode} size={320} />
            )}
          </Stack>
          <Container>
            <VStack flexDir={'column'} w={'full'}>
              <SimpleGrid
                columns={{ base: 1, sm: 1, md: 2 }}
                textAlign={'left'}
                w={'full'}
                justifyContent={'space-between'}
                pt={14}
                fontSize={'md'}
              >
                <VStack pl={{ base: 0, sm: 0, md: 10 }}>
                  <Text w={'full'}>Valor</Text>
                  <Text w={'full'} color={'#00A052'}>
                    {amount}
                  </Text>

                  <Text w={'full'} pt={6}>
                    Referência
                  </Text>
                  <Text w={'full'}>{externalReference}</Text>
                </VStack>

                <VStack pt={{ base: 4, sm: 4, md: 0 }}>
                  <Text w={'full'}>Destinatário</Text>
                  <Text w={'full'}>{pspReceiver || ' SAFEPAG PAGAMENTOS SA'}</Text>
                </VStack>
                <Box pl={{ base: 0, sm: 0, md: 10 }}>
                  <VStack pt={6}>
                    <Text w={'full'}>Copia e cola</Text>
                  </VStack>
                  <Stack maxW={{ base: 'sm', md: 'lg' }} flexDir={{ base: 'column', md: 'row' }}>
                    <Box maxW={'lg'} pr={{ base: 0, md: 12 }}>
                      <Text pb={{ base: 4, sm: 4, md: 0 }} pt={3} fontSize={10} whiteSpace={'pre-wrap'}>
                        {pixCopiaECola}
                      </Text>
                    </Box>
                    <Button
                      border={'1px solid black'}
                      rounded={0}
                      minW={{ base: 'full', sm: 'full', md: '20' }}
                      _hover={{ transform: 'scale(1.05)', transition: 'all .4s ease' }}
                      mr={{ base: 0, sm: 0, md: 12 }}
                      onClick={() =>
                        navigator.clipboard.writeText(`${pixCopiaECola}`).then(() => {
                          setCopiedText('COPIADO');
                        })
                      }
                    >
                      {copiedText}
                    </Button>
                  </Stack>
                </Box>
              </SimpleGrid>
            </VStack>
            {isLargerThan900 && (
              <Box w={'full'} pt={10}>
                <Alert />
              </Box>
            )}
          </Container>
        </Flex>
      </Box>
      {isSmallerThan900 && (
        <Box pt={4}>
          <Alert />
        </Box>
      )}
    </Container>
  );
};

export default Qrcode;
