import { cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

yup.addMethod(yup.string, 'isValidCpf', function (message: string) {
  return this.test('isValidCpf', message, (value) => {
    return cpf.isValid(value ? value : '0');
  });
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isValidCpf(message: string): StringSchema<TType, TContext>;
  }
}

export default yup;
