import React from 'react';
import { Box, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import TogglerBox from '../toggler-box/TogglerBox';
import { PAYMENT_OPTIONS } from '../../constants/payment';
import { useCheckout } from '../../contexts/CheckoutContext';

const PaymentToggler: React.FC = () => {
  const { isSelectedPayment, setIsSelectedPayment } = useCheckout();

  return (
    <Box>
      <Text pb={5}>Forma de pagamento</Text>
      <SimpleGrid columns={3} spacing={4} textAlign={'center'}>
        {PAYMENT_OPTIONS.map(({ id, name, src, w }) => (
          <TogglerBox
            id={id}
            setIsSelected={setIsSelectedPayment}
            focus={isSelectedPayment === id ? '#85D7B8' : '#F8F8F8'}
            key={id}
          >
            <Flex justifyContent={'center'} alignItems={'center'} h={'full'}>
              <Box>
                <Image src={src} w={w} h={'50px'} />
                <Text>{name}</Text>
              </Box>
            </Flex>
          </TogglerBox>
        ))}
      </SimpleGrid>
      {/* <Text textAlign={'right'} fontSize={12} pt={3} w={'full'}>
        Other alternatives {'>'}
      </Text> */}
    </Box>
  );
};

export default PaymentToggler;
