import React from 'react';
import { Center, Heading, Text, VStack } from '@chakra-ui/react';
import NavBar from '../components/navbar/NavBar';
import { useCheckout } from '../contexts/CheckoutContext';

const Error: React.FC = () => {
  const {
    error
  } = useCheckout();
  return (
    <>
      <NavBar />
      <Center minH="70vh">
        <VStack>
          <Heading>Erro ao realizar transação</Heading>
          {error && error.code ? <Text color={'red'}>Code : {error.code}</Text> : null}
        </VStack>
      </Center>
    </>
  );
};

export default Error;
