import pix from '../assets/images/pix.svg';
import { CheckoutPaymentType } from '../contexts/CheckoutContext';

export const PAYMENT_OPTIONS = [
  { id: 'PIX' as CheckoutPaymentType, name: 'Pix', src: pix, w: { base: '40px', sm: '40px', md: 'full' } }
];

export const PAYMENT_OBJ = {
  PIX: 'Pix',
};
